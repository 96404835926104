const systemManageRouter = {
  path: '/systemManage',
  name: 'SystemManage',
  redirect: '/systemManage/dictManage/list',
  component: () => import('@/views/systemManage/index.vue'),
  meta: { name: '系统设置', icon: 'el-icon-setting' },
  children: [
    // {
    //   path: '/systemManage/delivery/list',
    //   name: 'DeliveryList',
    //   component: () => import('@/views/systemManage/delivery/list.vue'),
    //   meta: { name: '运费模板' }
    // },
    // {
    //   path: '/systemManage/trade/setting',
    //   name: 'TradeSetting',
    //   component: () => import('@/views/systemManage/trade/list.vue'),
    //   meta: { name: '交易设置' }
    // },
    // {
    //   path: '/systemManage/express/list',
    //   name: 'ExpressList',
    //   component: () => import('@/views/systemManage/express/list.vue'),
    //   meta: { name: '物流公司' }
    // }

    // {
    //   path: '/systemManage/dictManage/list',
    //   name: 'DictManageList',
    //   component: () => import('@/views/systemManage/dictManage/index.vue'),
    //   meta: { name: '字典管理' }
    // },

    // {
    //   path: '/systemManage/dictValueManage',
    //   name: 'DictValueManage',
    //   component: () => import('@/views/systemManage/dictManage/list-dict-value.vue'),
    //   meta: { hidden: true, name: '字典值管理' }
    // },

    {
      path: '/systemManage/config/list',
      name: 'ConfigList',
      component: () => import('@/views/systemManage/config/list.vue'),
      meta: { name: '网站设置' }
    },

    {
      path: '/systemManage/kefu/list',
      name: 'KefuList',
      component: () => import('@/views/systemManage/kefu/list.vue'),
      meta: { name: '客服设置' }
    },
    {
      path: '/systemManage/banner/list',
      name: 'BannerList',
      component: () => import('@/views/systemManage/banner/list.vue'),
      meta: { name: 'banner设置' }
    },

    {
      path: '/systemManage/agreement/list',
      name: 'AgreementList',
      component: () => import('@/views/systemManage/agreement/list.vue'),
      meta: { name: '协议设置' }
    }
    // {
    //   path: '/systemManage/share/list',
    //   name: 'shareList',
    //   component: () => import('@/views/systemManage/share/list.vue'),
    //   meta: { name: '分享设置' }
    // },
    // {
    //   path: '/systemManage/mini/settings',
    //   name: 'MiniSettings',
    //   component: () => import('@/views/systemManage/mini-setting/list.vue'),
    //   meta: { name: '小程序设置' }
    // }
    // {
    //   path: '/systemManage/notice/list',
    //   name: 'NoticeList',
    //   component: () => import('@/views/systemManage/notice/index.vue'),
    //   meta: { name: '通知公告' }
    // },
    // {
    //   path: '/systemManage/notice/add',
    //   name: 'NoticeAdd',
    //   component: () => import('@/views/systemManage/notice/addEdit.vue'),
    //   meta: { name: '新增', hidden: true, activeMenu: '/systemManage/notice/list' }
    // },
    // {
    //   path: '/systemManage/notice/edit/:id?',
    //   name: 'NoticeEdit',
    //   component: () => import('@/views/systemManage/notice/addEdit.vue'),
    //   meta: { name: '编辑', hidden: true, activeMenu: '/systemManage/notice/list' }
    // }
  ]
}

export default systemManageRouter
