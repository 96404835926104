// 常量路由

import systemManageRouter from '@/router/modules/systemManage'

export const constantRoutes = [
  {
    path: '/',
    redirect: '/home',
    meta: {
      hidden: true
    }
  },
  {
    path: '/login',
    meta: {
      hidden: true
    },
    component: () => import('@/views/login/index')
  },
  {
    path: '/home',
    name: 'Home',
    meta: {
      icon: 'el-icon-s-home',
      name: '首页'
    },
    component: () => import('../views/home/index.vue')
  }
]

// 任意路由
export const anyRoutes = [
  //
]

// 动态路由
export const asyncRoutes = () => [
  // 店铺管理
  // {
  //   path: '/store',
  //   name: 'Store',
  //   component: () => import('@/views/store/index.vue'),
  //   meta: { name: '店铺管理', icon: 'el-icon-s-shop' },
  //   children: [
  //     {
  //       path: '/store/address/list',
  //       name: 'AddressList',
  //       component: () => import('@/views/store/address/list.vue'),
  //       meta: { name: '地址管理' }
  //     }
  //   ]
  // },
  // 商品管理
  {
    path: '/goods',
    name: 'Goods',
    component: () => import('@/views/goods/index.vue'),
    meta: { name: '商品管理', icon: 'el-icon-s-goods' },
    children: [
      {
        path: '/goods/list',
        name: 'GoodsList',
        component: () => import('@/views/goods/goods-list/list.vue'),
        meta: { name: '商品列表', keepAlive: true }
      },
      {
        path: '/goods/add',
        name: 'GoodsAdd',
        component: () => import('@/views/goods/goods-list/goods-form.vue'),
        meta: { name: '新增商品', hidden: true, activeMenu: '/goods/list' }
      },
      {
        path: '/goods/edit/:id?',
        name: 'GoodsEdit',
        component: () => import('@/views/goods/goods-list/goods-form.vue'),
        meta: { name: '编辑商品', hidden: true, activeMenu: '/goods/list' }
      },
      {
        path: '/goods/category/list',
        name: 'GoodsCategoryList',
        component: () => import('@/views/goods/goods-category/list.vue'),
        meta: { name: '商品分类' }
      },
      // {
      //   path: '/goods/service/list',
      //   name: 'GoodsServiceList',
      //   component: () => import('@/views/goods/goods-service/list.vue'),
      //   meta: { name: '服务承诺' }
      // },
      // {
      //   path: '/goods/comment/list',
      //   name: 'GoodsCommentList',
      //   component: () => import('@/views/goods/goods-comment/list.vue'),
      //   meta: { name: '商品评价' }
      // }
    ]
  },
  // 订单管理
  {
    path: '/order',
    name: 'Order',
    component: () => import('@/views/order/index.vue'),
    meta: { name: '订单管理', icon: 'el-icon-s-order' },
    children: [
      {
        path: '/order/list',
        name: 'OrderList',
        component: () => import('@/views/order/order/list.vue'),
        meta: { name: '积分订单' }
      },
      {
        path: '/order/coin/list',
        name: 'OrderCoinList',
        component: () => import('@/views/order/coin/list.vue'),
        meta: { name: '游戏币兑换记录' }
      },
      {
        path: '/order/jifen/list',
        name: 'OrderJifenList',
        component: () => import('@/views/order/jifen/list.vue'),
        meta: { name: '积分兑换记录' }
      },
    ]
  },
  // 用户管理
  {
    path: '/user',
    name: 'User',
    component: () => import('@/views/user/index.vue'),
    meta: { name: '用户管理', icon: 'el-icon-user-solid' },
    children: [
      {
        path: '/user/list',
        name: 'UserList',
        component: () => import('@/views/user/user/list.vue'),
        meta: { name: '用户列表' }
      }
    ]
  },
  // 会员管理
  // {
  //   path: '/vip',
  //   name: 'Vip',
  //   component: () => import('@/views/vip/index.vue'),
  //   meta: { name: '会员管理', icon: 'el-icon-user-solid' },
  //   children: [
  //     {
  //       path: '/vip/list',
  //       name: 'VipList',
  //       component: () => import('@/views/vip/vip-list/list.vue'),
  //       meta: { name: '会员列表', keepAlive: true }
  //     },
  //     {
  //       path: '/vip/level/list',
  //       name: 'VipLevelList',
  //       component: () => import('@/views/vip/vip-level/list.vue'),
  //       meta: { name: '会员等级', keepAlive: true }
  //     },
  //     {
  //       path: '/vip/recharge/list',
  //       name: 'VipRechargeList',
  //       component: () => import('@/views/vip/recharge-records/list.vue'),
  //       meta: { name: '充值记录', keepAlive: true }
  //     },
  //     {
  //       path: '/vip/plan/list',
  //       name: 'VipPlanList',
  //       component: () => import('@/views/vip/recharge-plan/list.vue'),
  //       meta: { name: '充值套餐', keepAlive: true }
  //     }
  //   ]
  // },
  // 文章管理
  // {
  //   path: '/article',
  //   name: 'Article',
  //   component: () => import('@/views/article/index.vue'),
  //   meta: { name: '文章管理', icon: 'el-icon-tickets' },
  //   children: [
  //     {
  //       path: '/article/list',
  //       name: 'ArticleList',
  //       component: () => import('@/views/article/article-list/list.vue'),
  //       meta: { name: '文章列表' }
  //     },
  //     {
  //       path: '/article/category/list',
  //       name: 'ArticleCategoryList',
  //       component: () => import('@/views/article/article-category/list.vue'),
  //       meta: { name: '文章分类' }
  //     },
  //     {
  //       path: '/article/help-center/list',
  //       name: 'HelpCenterList',
  //       component: () => import('@/views/article/help-center/list.vue'),
  //       meta: { name: '帮助中心' }
  //     }
  //   ]
  // },
  // 营销管理
  // {
  //   path: '/marketing',
  //   name: 'Marketing',
  //   component: () => import('@/views/marketing/index.vue'),
  //   meta: { name: '营销管理', icon: 'el-icon-s-marketing' },
  //   children: [
  //     {
  //       path: '/marketing/coupon/list',
  //       name: 'CouponList',
  //       component: () => import('@/views/marketing/coupon/list.vue'),
  //       meta: { name: '优惠券列表' }
  //     },
  //     {
  //       path: '/marketing/coupon/records',
  //       name: 'CouponRecords',
  //       component: () => import('@/views/marketing/coupon-records/list.vue'),
  //       meta: { name: '领券记录' }
  //     }
  //   ]
  // },
  // 权限管理
  {
    path: '/powerManage',
    name: 'PowerManage',
    redirect: '/powerManage/user/list',
    component: () => import('@/views/powerManage/index.vue'),
    meta: { name: '权限管理', icon: 'el-icon-menu' },
    children: [
      {
        path: '/powerManage/admin/list',
        name: 'PowerManageAdminList',
        component: () => import('@/views/powerManage/admin/list.vue'),
        meta: { name: '管理员' }
      },

      {
        path: '/powerManage/role/list',
        name: 'RoleList',
        component: () => import('@/views/powerManage/role/list.vue'),
        meta: { name: '角色管理' }
      },

      {
        path: '/powerManage/menu/list',
        name: 'MenuList',
        component: () => import('@/views/powerManage/menu/list.vue'),
        meta: { name: '菜单管理', keepAlive: true }
      }

      // {
      //   path: '/powerManage/dept/list',
      //   name: 'Dept',
      //   component: () => import('@/views/powerManage/dept/list.vue'),
      //   meta: { name: '部门管理' }
      // }
    ]
  },
  systemManageRouter
]
